import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import QuoteImage from '../assets/images/quote-banner.webp';

// Données des différents kits (tous les prix sont des nombres)
const kitsData = [
  {
    title: 'Kit Essentiel',
    items: [
      'Deux haut-parleurs QSC K10',
      'Un subwoofer Pioneer XPRS 115S',
      'Une perche à lumière 4 spots RGB + 4 effets',
      'Une console mixage Pioneer XDJ-RR',
      'Un micro filaire SHURE SM58',
      'Prestation montage + DJ sans supplément',
    ],
    price: 500,
  },
  {
    title: 'Kit Ambiance',
    items: [
      'Deux haut-parleurs QSC K10',
      'Deux subwoofers Pioneer XPRS 115S',
      'Une perche 4 spots RGB + 4 effets',
      'Une console mixage Pioneer XDJ-RR',
      'Un micro filaire SHURE SM58',
      'Un micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: 550,
  },
  {
    title: 'Kit Dynamique',
    items: [
      'Deux haut-parleurs QSC K10',
      'Deux subwoofers Pioneer XPRS 115S',
      'Une perche 4 spots RGB + 4 effets + 1 laser RGB',
      'Deux spots de sol RGB',
      'Une machine à brouillard JBSYSTEMS HAZE-700',
      'Une console mixage Pioneer XDJ-RR',
      'Un micro filaire SHURE SM58',
      'Un micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: 600,
  },
  {
    title: 'Kit Impact',
    items: [
      'Deux haut-parleurs QSC K12',
      'Un subwoofer QSC KW181',
      'Deux perches 8 spots RGB + 8 effets + 2 lasers RGB',
      'Quatre spots de sol RGB',
      'Une machine à brouillard JBSYSTEMS HAZE-700',
      'Une table de mixage Pioneer DKM-900NXS',
      'Deux lecteurs Pioneer CDJ-2000NXS',
      'Un micro filaire SHURE SM58',
      'Un micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: 700,
  },
  {
    title: 'Kit Prestige',
    items: [
      'Deux haut-parleurs QSC K12',
      'Deux caissons de basse QSC KW181',
      'Structure 4 mètres + 4 scans + 2 effets beam + 1 laser RGB',
      'Six spots de sol RGB',
      'Machine à brouillard JBSYSTEMS HAZE-700',
      'Table de mixage Pioneer DJM-900NXS',
      'Deux lecteurs Pioneer CDJ-2000NXS',
      'Micro filaire SHURE SM58',
      'Micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: 800,
  },
  {
    title: 'Kit Ultime',
    items: [
      'Quatre haut-parleurs QSC K12',
      'Deux caissons de basse QSC KW181',
      'Structure 5 mètres avec 6 scanneurs, 4 effets beam + 2 lasers RGB',
      'Huit spots de sol RGB',
      'Machine à brouillard JBSYSTEMS HAZE-700',
      'Table de mixage Pioneer DJM-900NXS',
      'Deux lecteurs Pioneer CDJ-2000NXS',
      'Un micro filaire SHURE SM58',
      'Un micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: 1000,
  },
  {
    title: 'Kit Quiz interactif',
    items: [
      'Kit quiz avec télécommande',
      'Un écran géant + projecteur',
      'Écran LCD sur pied (supplément de 35€)',
      'Une console mixage Pioneer XDJ-RR',
      'Quiz selon vos goûts',
      'Quatre spots de sol RGB',
      'Kit son et lumière sur devis',
      'Prestation montage + DJ sans supplément',
    ],
    price: 200,
  },
  {
    title: 'Kit Karaoké',
    items: [
      'Deux micros filaires sur pied SHURE SM58',
      'Un retour chanteur Pioneer XPRS 15',
      'Un écran géant + projecteur',
      'Écran LCD sur pied (supplément de 35€)',
      'Une console mixage Pioneer XDJ-RR',
      'Plus de 70.000 chansons',
      'Quatre spots de sol RGB',
      'Kit son et lumière sur devis',
      'Prestation montage + DJ sans supplément',
    ],
    price: 200,
  },
];

// Kits son & lumière pour compléter un kit Karaoké ou Quiz
const soundLightKitsData = [
  'Kit Essentiel',
  'Kit Ambiance',
  'Kit Dynamique',
  'Kit Impact',
  'Kit Prestige',
  'Kit Ultime',
];

// Add-ons possibles, avec prix (ou "sur devis")
const addonsPricing = [
  { value: 'Spot LED Sol x 4', label: 'Spot LED Sol x 4', price: 30 },
  { value: 'Spot LED Sol x 8', label: 'Spot LED Sol x 8', price: 60 },
  { value: 'Spot LED Sol x 12', label: 'Spot LED Sol x 12', price: 90 },
  { value: 'Micro filaire supplémentaire', label: 'Micro filaire supplémentaire', price: 10 },
  { value: 'Micro sans fil supplémentaire', label: 'Micro sans fil supplémentaire', price: 25 },
  { value: 'Écrans LED supplémentaires', label: 'Écrans LED supplémentaires', price: 35 },
  { value: 'Écrans LED supplémentaires X2', label: 'Écrans LED supplémentaires X2', price: 70 },
  { value: 'Projecteur + écran 2,40m', label: 'Projecteur + écran 2,40m', price: 70 },
  { value: 'Hauts-parleur QSC K12 X2', label: 'Hauts-parleur QSC K12 X2', price: 60 },
  { value: 'Caisson de basse QSC KW181 X2', label: 'Caisson de basse QSC KW181 X2', price: 90 },
  { value: 'Structure lumière personnalisée', label: 'Structure lumière personnalisée', price: 'sur devis' },
  { value: 'Son personnalisé', label: 'Son personnalisé', price: 'sur devis' },
];

// Construction de l'objet JSON-LD (OfferCatalog) pour le SEO
const offerCatalogJsonLd = {
  '@context': 'https://schema.org',
  '@type': 'OfferCatalog',
  name: 'Nice Events Sonorisation - Kits Disponibles',
  itemListElement: kitsData.map((kit, index) => ({
    '@type': 'Offer',
    position: index + 1,
    itemOffered: {
      '@type': 'Service',
      name: kit.title,
      description: kit.items.join(', '),
    },
    price: kit.price.toString(),
    priceCurrency: 'EUR',
  })),
};

function QuoteForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    selectedKit: '',
    eventDate: '',
    eventStreet: '',
    eventNumber: '',
    eventZip: '',
    eventCity: '',
    eventType: '',
    message: '',
    addOns: [],
    termsAccepted: '',
    soundLightKit: '',
  });

  const [rgpdAccepted, setRgpdAccepted] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Si Karaoké ou Quiz => besoin d’un kit son/lumière
  const needsSoundLightKit =
    formData.selectedKit === 'Kit Karaoké' ||
    formData.selectedKit === 'Kit Quiz interactif';

  // Calcul du prix total
  const [computedPrice, setComputedPrice] = useState('0'); // "xxx" ou "Sur devis"

  // -----------------------
  // Récupération du localStorage au premier rendu
  // -----------------------
  useEffect(() => {
    const savedFormData = localStorage.getItem('quoteFormData');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
    const savedRgpdAccepted = localStorage.getItem('rgpdAccepted');
    if (savedRgpdAccepted) {
      setRgpdAccepted(JSON.parse(savedRgpdAccepted));
    }
  }, []);

  // -----------------------
  // Sauvegarde des données dans le localStorage à chaque changement
  // -----------------------
  useEffect(() => {
    localStorage.setItem('quoteFormData', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    localStorage.setItem('rgpdAccepted', JSON.stringify(rgpdAccepted));
  }, [rgpdAccepted]);

  // Calcul dynamique du prix (quand formData ou needsSoundLightKit changent)
  useEffect(() => {
    let total = 0;
    let isSurDevis = false;

    // 1) Prix du kit principal
    const selectedKitObj = kitsData.find((k) => k.title === formData.selectedKit);
    if (selectedKitObj) {
      total += selectedKitObj.price;
    }

    // 2) Si Karaoké/Quiz + kit son-lumière => ajouter ce deuxième kit
    if (needsSoundLightKit && formData.soundLightKit) {
      const secondKitObj = kitsData.find((k) => k.title === formData.soundLightKit);
      if (secondKitObj) {
        total += secondKitObj.price;
      }
    }

    // 3) Prix des add-ons
    for (const addOnValue of formData.addOns) {
      const addOnObj = addonsPricing.find((a) => a.value === addOnValue);
      if (addOnObj) {
        if (addOnObj.price === 'sur devis') {
          isSurDevis = true;
        } else {
          total += addOnObj.price;
        }
      }
    }

    // 4) Décision finale : "Sur devis" ou montant
    if (isSurDevis) {
      setComputedPrice('Sur devis');
    } else {
      setComputedPrice(`${total}`);
    }
  }, [formData, needsSoundLightKit]);

  // Gestion des champs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Cases à cocher (add-ons)
  const handleAddOnChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => {
      if (checked) {
        return { ...prev, addOns: [...prev.addOns, value] };
      }
      return { ...prev, addOns: prev.addOns.filter((a) => a !== value) };
    });
  };

  // RGPD
  const handleRgpdChange = (e) => {
    setRgpdAccepted(e.target.checked);
  };

  // CGV
  const handleTermsChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      termsAccepted: e.target.value,
    }));
  };

  // Validation avant envoi
  const isSubmitDisabled =
    loading ||
    !formData.firstName.trim() ||
    !formData.lastName.trim() ||
    !formData.email.trim() ||
    !formData.selectedKit ||
    !formData.eventDate ||
    !formData.eventStreet.trim() ||
    !formData.eventNumber.trim() ||
    !formData.eventZip.trim() ||
    !formData.eventCity.trim() ||
    !rgpdAccepted ||
    formData.termsAccepted !== 'yes' ||
    (needsSoundLightKit && !formData.soundLightKit);

  // Soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage('');
    setError('');
    setLoading(true);

    // Calculer le prix HTVA et TVAC pour envoi au backend
    let priceHtva = 'Sur devis';
    let priceTvac = 'Sur devis';
    if (computedPrice !== 'Sur devis') {
      const numericPrice = parseFloat(computedPrice);
      if (!isNaN(numericPrice)) {
        priceHtva = numericPrice.toFixed(2);       // ex: "500.00"
        priceTvac = (numericPrice * 1.21).toFixed(2); // ex: "605.00"
      }
    }

    try {
      const payload = {
        ...formData,
        computedPrice,  // Montant brut ou "Sur devis"
        priceHtva,      // Montant HTVA ou "Sur devis"
        priceTvac,      // Montant TVAC ou "Sur devis"
      };

      const response = await axios.post('/api/quote', payload);
      if (response.data.success) {
        setStatusMessage('Votre demande de devis a bien été envoyée !');

        // Réinitialiser le formulaire
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          selectedKit: '',
          eventDate: '',
          eventStreet: '',
          eventNumber: '',
          eventZip: '',
          eventCity: '',
          eventType: '',
          message: '',
          addOns: [],
          termsAccepted: '',
          soundLightKit: '',
        });
        setRgpdAccepted(false);
        setComputedPrice('0');

        // Vider le localStorage si tout s'est bien passé
        localStorage.removeItem('quoteFormData');
        localStorage.removeItem('rgpdAccepted');
      } else {
        setError('Une erreur est survenue, merci de réessayer plus tard.');
      }
    } catch (err) {
      setError('Une erreur est survenue, merci de réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Demande de devis rapide | Nice Events Sonorisation</title>
        <meta
          name="description"
          content="Obtenez rapidement votre devis personnalisé pour nos services DJ, son, lumière, quiz interactif ou karaoké."
        />
        <link rel="canonical" href="https://niceevents.be/quote" />

        {/* Open Graph */}
        <meta
          property="og:title"
          content="Demande de devis rapide | Nice Events Sonorisation"
        />
        <meta
          property="og:description"
          content="Obtenez rapidement un devis pour nos différentes formules son et lumière, quiz et karaoké."
        />
        <meta property="og:url" content="https://niceevents.be/quote" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://niceevents.be/images/og-image.jpg"
        />

        {/* JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify(offerCatalogJsonLd)}
        </script>
      </Helmet>

      {/* Bannière */}
      <div
        className="quote-banner position-relative text-center text-light d-flex align-items-center justify-content-center mb-5"
        style={{ minHeight: '300px' }}
      >
        <img
          src={QuoteImage}
          alt="Bannière pour le formulaire de devis rapide"
          className="img-fluid w-100"
          style={{ height: '400px', objectFit: 'cover' }}
        />
        <div className="overlay-animated" />
        <div
          className="position-absolute top-50 start-50 translate-middle hero-text-effect"
          style={{ zIndex: 2 }}
        >
          <h1 className="display-3 fw-bold">Demande de devis rapide</h1>
          <p className="lead">
            Choisissez le kit qui vous convient ou demandez un plan personnalisé.
            Nous vous répondrons dès que possible !
          </p>
        </div>
      </div>

      {/* Liste des kits */}
      <div className="container mb-5 fade-in">
        <h2 className="mb-4 text-center">Nos Kits Disponibles</h2>
        <div className="row g-4">
          {kitsData.map((kit, idx) => (
            <div key={idx} className="col-md-6 col-lg-4">
              <div
                className="card h-100 shadow-sm"
                style={{ backgroundColor: '#343a40' }}
              >
                <div className="card-body text-white">
                  <h3 className="card-title">{kit.title}</h3>
                  <ul className="mb-3">
                    {kit.items.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                  <h5 className="fw-bold">{kit.price}€ htva</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Formulaire de devis */}
      <div className="container py-5 fade-in">
        <div className="row">
          {/* Options Supplémentaires */}
          <div className="col-md-6 mb-4">
            <h2>Options Supplémentaires</h2>
            <p>
              En plus de nos kits standard, vous pouvez sélectionner des
              équipements supplémentaires&nbsp;:
            </p>

            {addonsPricing.map((addon) => (
              <div className="form-check mb-2" key={addon.value}>
                <input
                  type="checkbox"
                  id={addon.value}
                  value={addon.value}
                  className="form-check-input"
                  onChange={handleAddOnChange}
                  checked={formData.addOns.includes(addon.value)}
                />
                <label htmlFor={addon.value} className="form-check-label">
                  {addon.label}{' '}
                  {addon.price === 'sur devis'
                    ? '(Sur devis)'
                    : `(+${addon.price}€)`}
                </label>
              </div>
            ))}
          </div>

          {/* Formulaire proprement dit */}
          <div className="col-md-6">
            <h2 className="mb-3">Formulaire de Devis</h2>
            <form onSubmit={handleSubmit}>
              {/* Nom / Prénom */}
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                  Prénom *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  placeholder="Votre prénom"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Nom *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  placeholder="Votre nom"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Téléphone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Votre numéro de téléphone"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Adresse e-mail *
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="exemple@domaine.com"
                />
              </div>

              {/* Sélection du kit principal */}
              <div className="mb-3">
                <label htmlFor="selectedKit" className="form-label">
                  Sélectionnez un kit *
                </label>
                <select
                  className="form-select"
                  id="selectedKit"
                  name="selectedKit"
                  value={formData.selectedKit}
                  onChange={handleChange}
                  required
                >
                  <option value="">-- Choisissez un kit --</option>
                  {kitsData.map((kit, idx) => (
                    <option key={idx} value={kit.title}>
                      {kit.title} ({kit.price}€ htva)
                    </option>
                  ))}
                </select>
              </div>

              {/* Karaoké ou Quiz => kit son & lumière */}
              {needsSoundLightKit && (
                <div className="mb-3">
                  <label htmlFor="soundLightKit" className="form-label">
                    Choisissez un kit Son & Lumière pour compléter *
                  </label>
                  <select
                    className="form-select"
                    id="soundLightKit"
                    name="soundLightKit"
                    value={formData.soundLightKit}
                    onChange={handleChange}
                    required
                  >
                    <option value="">-- Sélectionnez un kit --</option>
                    {soundLightKitsData.map((kitName, idx) => {
                      const kitObj = kitsData.find((k) => k.title === kitName);
                      return (
                        <option key={idx} value={kitName}>
                          {kitName} {kitObj && `(${kitObj.price}€ htva)`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {/* Date de l'événement */}
              <div className="mb-3">
                <label htmlFor="eventDate" className="form-label">
                  Date de l'événement *
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="eventDate"
                  name="eventDate"
                  value={formData.eventDate}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Adresse complète du lieu */}
              <label className="form-label">Adresse du lieu *</label>
              <div className="row g-3 mb-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    id="eventStreet"
                    name="eventStreet"
                    value={formData.eventStreet}
                    onChange={handleChange}
                    required
                    placeholder="Rue"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    id="eventNumber"
                    name="eventNumber"
                    value={formData.eventNumber}
                    onChange={handleChange}
                    required
                    placeholder="Numéro"
                  />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    id="eventZip"
                    name="eventZip"
                    value={formData.eventZip}
                    onChange={handleChange}
                    required
                    placeholder="Code postal"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    id="eventCity"
                    name="eventCity"
                    value={formData.eventCity}
                    onChange={handleChange}
                    required
                    placeholder="Localité"
                  />
                </div>
              </div>

              {/* Type d'événement */}
              <div className="mb-3">
                <label htmlFor="eventType" className="form-label">
                  Type d'événement
                </label>
                <select
                  className="form-select"
                  id="eventType"
                  name="eventType"
                  value={formData.eventType}
                  onChange={handleChange}
                >
                  <option value="">-- Sélectionnez --</option>
                  <option value="Mariage">Mariage</option>
                  <option value="Anniversaire">Anniversaire</option>
                  <option value="Entreprise">Entreprise</option>
                  <option value="Fête locale">Fête locale</option>
                  <option value="Autre">Autre</option>
                </select>
              </div>

              {/* Message */}
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Informations complémentaires
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Précisez vos demandes particulières, etc."
                />
              </div>

              {/* RGPD */}
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rgpdCheck"
                  checked={rgpdAccepted}
                  onChange={handleRgpdChange}
                  required
                />
                <label className="form-check-label" htmlFor="rgpdCheck">
                  J'accepte la{' '}
                  <Link
                    to="/privacy-policy"
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    politique de confidentialité
                  </Link>{' '}
                  *
                </label>
              </div>

              {/* CGV */}
              <div className="mb-3">
                <label className="form-label d-block">
                  Conditions Générales de Vente *
                  <br />
                  <Link
                    to="/terms-and-condition"
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    Voir les CGV
                  </Link>
                </label>
                <div className="form-check">
                  <input
                    type="radio"
                    id="termsYes"
                    name="termsAccepted"
                    value="yes"
                    className="form-check-input"
                    checked={formData.termsAccepted === 'yes'}
                    onChange={handleTermsChange}
                    required
                  />
                  <label htmlFor="termsYes" className="form-check-label">
                    J'accepte
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="termsNo"
                    name="termsAccepted"
                    value="no"
                    className="form-check-input"
                    checked={formData.termsAccepted === 'no'}
                    onChange={handleTermsChange}
                    required
                  />
                  <label htmlFor="termsNo" className="form-check-label">
                    Je n'accepte pas
                  </label>
                </div>
              </div>

              {/* Affichage du prix total */}
              {computedPrice === 'Sur devis' ? (
                <div className="mb-3">
                  <h4>Prix total estimé : Sur devis</h4>
                  <small className="text-muted">
                    (Au moins un élément est « sur devis »)
                  </small>
                </div>
              ) : (
                <div className="mb-3">
                  <h4>
                    Prix total estimé HTVA :{' '}
                    {parseFloat(computedPrice).toFixed(2)}€
                  </h4>
                  <h4>
                    Prix total estimé TVAC :{' '}
                    {(parseFloat(computedPrice) * 1.21).toFixed(2)}€
                  </h4>
                </div>
              )}

              <p className="text-muted">* Champs obligatoires</p>

              {/* Messages succès / erreur */}
              {statusMessage && (
                <div className="alert alert-success" role="alert">
                  {statusMessage}
                </div>
              )}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              {/* Bouton d'envoi */}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitDisabled}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Envoi en cours...
                  </>
                ) : (
                  'Envoyer votre demande'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Décor bas de page (facultatif) */}
      <svg
        className="section-decorator"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#d32f2f"
          fillOpacity="1"
          d="M0,256L60,224C120,192,240,128,360,112C480,96,600,128,720,138.7C840,149,960,139,1080,149.3C1200,160,1320,192,1380,208L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </>
  );
}

export default QuoteForm;
