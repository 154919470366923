import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AnimationsEvents from '../assets/images/animations-events.webp';
import InteractiveQuiz from '../assets/images/interactive-quiz.webp';
import Karaoke2 from '../assets/images/karaoke2.webp';
import Animations from '../assets/images/animations.webp';
import QuizClipart from '../assets/images/quiz-clipart.webp';
import Karaoke3 from '../assets/images/karaoke3.webp';

function Services() {
  const [activeModal, setActiveModal] = useState(null);
  const openModal = (id) => setActiveModal(id);
  const closeModal = () => setActiveModal(null);

  const ModalIcon = () => (
    <svg
      width="24"
      height="24"
      fill="currentColor"
      className="me-2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" />
    </svg>
  );

  return (
    <>
      <Helmet>
        {/* Title optimisé */}
        <title>Nos Services | DJ, Quiz Interactifs & Karaoké - Nice Events Sonorisation</title>
        {/* Meta Description optimisée ~155 caractères */}
        <meta
          name="description"
          content="Découvrez les services de Nice Events Sonorisation pour la Belgique et la France : DJ, quiz interactifs, karaoké et matériel haut de gamme."
        />
        <link rel="canonical" href="https://niceevents.be/services" />

        {/* Open Graph - Réseaux Sociaux */}
        <meta
          property="og:title"
          content="Nos Services | DJ, Quiz Interactifs & Karaoké - Nice Events Sonorisation"
        />
        <meta
          property="og:description"
          content="Animations DJ sur-mesure, quiz interactifs captivants, karaoké festif et matériel professionnel pour vos événements."
        />
        <meta property="og:url" content="https://niceevents.be/services" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://niceevents.be/images/og-image.jpg" />

        {/* JSON-LD Structuré SEO optimisé pour les services */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Service',
            serviceType: [
              'Animation DJ',
              'Quiz interactif',
              'Karaoké',
              'Location de matériel événementiel',
            ],
            provider: {
              '@type': 'Organization',
              name: 'Nice Events Sonorisation',
              url: 'https://niceevents.be',
              logo: 'https://niceevents.be/images/logo.jpg',
            },
            areaServed: ['BE', 'FR'],
            description:
              'Animations DJ professionnelles, quiz interactifs dynamiques, karaoké et matériel événementiel de haute qualité.',
          })}
        </script>
      </Helmet>

      <div className="container py-5 fade-in">
        <h1 className="mb-4 text-center">Nos Services</h1>
        <p className="lead text-center mb-5">
          Des solutions sur mesure pour transformer vos événements en moments magiques
          <br />
          <Link style={{ textDecoration: 'underline' }} to="/contact">
            Contactez-nous
          </Link>{' '}
          pour plus d'informations
        </p>

        <div className="row">
          {/* Service 1 : Animations & Événements */}
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <img
                src={AnimationsEvents}
                alt="Animations & Événements - Illustration des animations DJ"
                className="card-img-top img-fluid"
              />
              <div className="card-body">
                <h5 className="card-title">Animations & Événements</h5>
                <p className="card-text">
                  Faites vibrer vos soirées avec des animations DJ innovantes.
                </p>
                <button className="btn btn-primary" onClick={() => openModal(1)}>
                  En savoir plus
                </button>
              </div>
            </div>
          </div>

          {activeModal === 1 && (
            <div
              className="modal show d-block modal-overlay"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="modalTitle"
              aria-modal="true"
            >
              <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content bg-dark text-light">
                  <div className="modal-header">
                    <h5 className="modal-title" id="modalTitle">
                      <ModalIcon /> Animations & Événements
                    </h5>
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      onClick={closeModal}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <article itemScope itemType="http://schema.org/Event">
                      <header>
                        <h2 itemProp="name">Service d'Animations DJ sur-mesure</h2>
                      </header>
                      <section>
                        <p itemProp="description">
                          Découvrez notre service d'animations DJ professionnels, conçu pour
                          transformer chaque événement en une expérience inoubliable et
                          captivante. Notre expertise musicale et nos performances
                          interactives créent une ambiance festive et dynamique.
                        </p>
                        <p itemProp="description">
                          Nos prestations incluent des sets musicaux adaptés, des animations
                          interactives et des spectacles personnalisés, garantissant une
                          satisfaction maximale pour vos invités. Laissez-nous faire de
                          votre événement un moment unique grâce à notre savoir-faire
                          reconnu.
                        </p>
                      </section>
                      <figure>
                        <img
                          src={Animations}
                          alt="DJ en action lors d'une animation événementielle professionnelle"
                          className="img-fluid rounded mt-3"
                          itemProp="image"
                        />
                      </figure>
                    </article>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closeModal}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Service 2 : Quiz Interactif */}
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <img
                src={InteractiveQuiz}
                alt="Quiz Interactif - Illustration du quiz interactif"
                className="card-img-top img-fluid"
              />
              <div className="card-body">
                <h5 className="card-title">Quiz Interactif</h5>
                <p className="card-text">
                  Engagez vos invités avec un quiz interactif et ludique.
                </p>
                <button className="btn btn-primary" onClick={() => openModal(2)}>
                  En savoir plus
                </button>
              </div>
            </div>
          </div>

          {activeModal === 2 && (
            <div
              className="modal show d-block modal-overlay"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="modalTitleQuiz"
              aria-modal="true"
            >
              <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content bg-dark text-light">
                  <div className="modal-header">
                    <h5 className="modal-title" id="modalTitleQuiz">
                      <ModalIcon /> Quiz Interactif
                    </h5>
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      onClick={closeModal}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <article itemScope itemType="http://schema.org/Event">
                      <header>
                        <h2 itemProp="name">Quiz Interactif et Ludique</h2>
                      </header>
                      <section>
                        <p itemProp="description">
                          Dynamisez vos événements grâce à notre quiz interactif innovant.
                          Ce service propose des questions captivantes et un système de
                          scoring en temps réel pour engager vos invités dans une
                          compétition amusante.
                        </p>
                        <p itemProp="description">
                          Conçu pour stimuler l'interaction et créer une ambiance
                          conviviale, ce quiz interactif transforme chaque moment en une
                          expérience ludique et mémorable.
                        </p>
                      </section>
                      <figure>
                        <img
                          src={QuizClipart}
                          alt="Quiz Interactif - Illustration du quiz interactif"
                          className="img-fluid rounded mt-3"
                        />
                      </figure>
                    </article>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closeModal}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Service 3 : Karaoké */}
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <img
                src={Karaoke2}
                alt="Karaoké - Illustration du karaoké"
                className="card-img-top img-fluid"
              />
              <div className="card-body">
                <h5 className="card-title">Karaoké</h5>
                <p className="card-text">
                  Laissez éclater votre talent avec notre karaoké haut de gamme.
                </p>
                <button className="btn btn-primary" onClick={() => openModal(3)}>
                  En savoir plus
                </button>
              </div>
            </div>
          </div>

          {activeModal === 3 && (
            <div
              className="modal show d-block modal-overlay"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="modalTitleKaraoke"
              aria-modal="true"
            >
              <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content bg-dark text-light">
                  <div className="modal-header">
                    <h5 className="modal-title" id="modalTitleKaraoke">
                      <ModalIcon /> Karaoké
                    </h5>
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      onClick={closeModal}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <article itemScope itemType="http://schema.org/Event">
                      <header>
                        <h2 itemProp="name">Service Karaoké pour Événements</h2>
                      </header>
                      <section>
                        <p itemProp="description">
                          Plongez dans une expérience musicale inoubliable grâce à notre
                          service de karaoké interactif. Profitez d'une vaste bibliothèque
                          de chansons pour animer vos soirées et encourager la participation
                          de vos invités.
                        </p>
                        <p itemProp="description">
                          Idéal pour des soirées festives et conviviales, notre karaoké
                          offre une ambiance dynamique et divertissante, garantissant des
                          moments mémorables.
                        </p>
                      </section>
                      <figure>
                        <img
                          src={Karaoke3}
                          alt="Exemple de karaoké interactif pour animer vos événements"
                          className="img-fluid rounded mt-3"
                        />
                      </figure>
                    </article>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closeModal}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Lien vers la page d'accueil et vers les kits */}
        <div className="text-center mt-5">
          <p>
            Pour découvrir l’ensemble de nos prestations, rendez-vous sur{' '}
            <Link to="/">notre page d’accueil</Link>.
          </p>
          <Link to="/kits" className="btn btn-primary btn-lg">
            Voir nos kits
          </Link>
        </div>
      </div>
      {/* Décor bas de page (facultatif, style cohérent) */}
      <svg
        className="section-decorator"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#d32f2f"
          fillOpacity="1"
          d="M0,256L60,224C120,192,240,128,360,112C480,96,600,128,720,138.7C840,149,960,139,1080,149.3C1200,160,1320,192,1380,208L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </>
  );
}

export default Services;
