import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import CookieBanner from './components/CookieBanner';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from './pages/Home';
import Services from './pages/Services';
import Kits from './pages/Kits';
import QuoteForm from './pages/QuoteForm';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LegalMentions from './pages/LegalMentions';
import TermsAndConditions from './pages/TermsAndConditions';
import NotFound from './pages/NotFound';

// Composant pour gérer le scroll en haut de page à chaque changement de route
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      {/* Le composant ScrollToTop doit être rendu à l'intérieur du Router */}
      <ScrollToTop />
      <div className="d-flex flex-column min-vh-100 bg-dark text-light">
        <Navbar />
        <CookieBanner />
        <div className="flex-grow-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/kits" element={<Kits />} />
            <Route path="/quote-form" element={<QuoteForm />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/legal-mentions" element={<LegalMentions />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            {/* Catch-all pour la 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
