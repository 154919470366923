import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title>Conditions Générales de Vente | All diffusion (Nice Events Sonorisation)</title>
        <meta
          name="description"
          content="Conditions Générales de Vente d’All diffusion (Nice Events Sonorisation), BCE 1016.199.219, TVA idem. Acompte non remboursable de 20%, modalités de réservations, report, annulation."
        />
        <link rel="canonical" href="https://niceevents.be/terms-and-conditions" />

        {/* Open Graph - Réseaux Sociaux */}
        <meta
          property="og:title"
          content="Conditions Générales de Vente | All diffusion (Nice Events Sonorisation)"
        />
        <meta
          property="og:description"
          content="Conditions Générales de Vente d’All diffusion (Nice Events Sonorisation) : acompte 20%, prestations DJ, karaoké, son, lumière, vidéo, conditions d’annulation et de report."
        />
        <meta property="og:url" content="https://niceevents.be/terms-and-conditions" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://niceevents.be/images/og-image.jpg"
        />

        {/* Twitter Cards (optionnel, actuellement en commentaire) */}
        {/*
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Conditions Générales de Vente | All diffusion (Nice Events Sonorisation)"
        />
        <meta
          name="twitter:description"
          content="Conditions Générales de Vente d’All diffusion (Nice Events Sonorisation) : acompte 20%, prestations DJ, karaoké, son, lumière, vidéo, conditions d’annulation et de report."
        />
        <meta name="twitter:image" content="https://niceevents.be/images/og-image.jpg" />
        */}

        {/* JSON-LD Structuré SEO pour les Conditions Générales de Vente */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            url: "https://niceevents.be/terms-and-conditions",
            name: "Conditions Générales de Vente | All diffusion (Nice Events Sonorisation)",
            description:
              "Consultez les Conditions Générales de Vente d’All diffusion (Nice Events Sonorisation), BCE 1016.199.219, TVA idem, pour les prestations événementielles : sonorisation, éclairage, vidéo, DJ, karaoké."
          })}
        </script>
      </Helmet>

      {/* Section principale avec fond image responsive */}
      <section
        className="policy-page position-relative text-light"
        style={{
          backgroundColor: '#333',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '600px',
        }}
        role="main"
      >
        {/* Overlay sombre */}
        <div
          className="overlay position-absolute w-100 h-100"
          style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
          aria-hidden="true"
        ></div>

        <div
          className="policy-content container position-relative"
          style={{ zIndex: 1, paddingTop: '100px', paddingBottom: '100px' }}
        >
          <div className="content-frame">
            <h1 className="display-4 text-center mb-4">
              Conditions Générales de Vente
            </h1>
            <p className="lead text-center">
              Les présentes Conditions Générales de Vente (CGV) régissent les rapports
              entre vous (ci-après le <strong>« Client »</strong>) et la société
              <strong> All diffusion</strong>, exploitant le nom commercial « Nice Events
              Sonorisation », inscrite à la Banque-Carrefour des Entreprises (BCE) sous le
              numéro <strong>1016.199.219</strong> (TVA identique), dont le siège social
              est sis Rue du Château 13, 5190 Jemeppe-sur-Sambre, Belgique (ci-après
              <strong> « Nous »</strong> ou <strong>« All diffusion »</strong>).
            </p>

            <div className="rich-text mt-5">
              <h2>1. Champ d’application</h2>
              <p>
                Les présentes CGV s’appliquent à toutes nos prestations de
                sonorisation, éclairage, vidéo, DJ, karaoké et autres services liés à
                l’animation d’événements (mariages, anniversaires, soirées
                d’entreprise, etc.). Le Client déclare avoir pris connaissance de ces CGV
                et les accepter sans réserve avant toute réservation.
              </p>

              <h2>2. Devis et réservation</h2>
              <ul>
                <li>
                  <strong>Devis gratuit :</strong> Un devis détaillant la nature de la
                  prestation, les tarifs et les éventuels suppléments est transmis au
                  Client à sa demande. Les prix sont en euros et peuvent être indiqués
                  TVA incluse ou excluse, selon mention.
                </li>
                <li>
                  <strong>Confirmation :</strong> La réservation est considérée comme
                  définitive dès réception d’un acompte de <strong>20%</strong> du
                  montant total de la prestation. Le paiement de l’acompte emporte
                  acceptation ferme et définitive du devis et des présentes CGV.
                </li>
                <li>
                  <strong>Acompte non remboursable :</strong> En raison de la
                  personnalisation de nos prestations et de la mobilisation de nos
                  ressources, l’acompte versé est <strong>strictement non
                  remboursable</strong>, sauf dispositions légales impératives
                  contraires.
                </li>
              </ul>

              <h2>3. Prestations proposées</h2>
              <ul>
                <li>
                  <strong>Services :</strong> Nous proposons des prestations
                  personnalisées comprenant, selon les besoins, la sonorisation, le
                  matériel d’éclairage, la projection vidéo, le DJing, le karaoké et toute
                  autre forme d’animation musicale ou audiovisuelle.
                </li>
                <li>
                  <strong>Matériel :</strong> Tous nos équipements sont fournis et mis en
                  œuvre par un de nos collaborateurs, dont la prestation est incluse dans
                  le tarif indiqué. Aucun matériel ne peut être loué sans présence de
                  notre personnel pour l’installation et/ou l’exploitation.
                </li>
                <li>
                  <strong>Personnalisation :</strong> Chaque événement est conçu sur
                  mesure, en fonction des préférences et contraintes du Client
                  (ex. thème, style musical, type de salle, configuration technique).
                </li>
              </ul>

              <h2>4. Tarifs et frais de déplacement</h2>
              <p>
                Sauf indication contraire, nos tarifs s’entendent pour des interventions
                réalisées dans un rayon de <strong>50 km</strong> autour de notre siège
                social (Rue du Château 13, 5190 Jemeppe-sur-Sambre). Au-delà de 50 km,
                des frais supplémentaires de <strong>0,35 € par km</strong> (trajet
                aller-retour) peuvent être facturés. Le montant exact de ces frais est
                indiqué au devis ou communiqué au Client avant la validation.
              </p>

              <h2>5. Paiement du solde</h2>
              <p>
                Le solde restant (80% du montant) est payable selon les modalités
                indiquées sur le devis, généralement au plus tard le jour de la
                prestation ou selon l’échéance convenue. Toute somme non payée à
                l’échéance convenue pourra être majorée des pénalités de retard
                prévues par la législation belge en vigueur.
              </p>

              <h2>6. Annulation et report</h2>
              <ul>
                <li>
                  <strong>Annulation par le Client :</strong> Toute demande d’annulation
                  doit être notifiée par écrit (email ou courrier). L’acompte demeure
                  acquis à All diffusion. Si l’annulation intervient moins de 14 jours
                  avant la date prévue de la prestation, All diffusion se réserve le
                  droit de facturer jusqu’à 100% du montant total, sauf raison légitime
                  prévue par la loi.
                </li>
                <li>
                  <strong>Report de date :</strong> En cas de report sollicité par le
                  Client, l’acompte déjà versé reste acquis. Nous ferons notre possible
                  pour trouver une date alternative, sous réserve de disponibilité de
                  nos équipes et du matériel.
                </li>
                <li>
                  <strong>Annulation par All diffusion :</strong> En cas de force
                  majeure (ex. panne majeure, maladie grave, pandémie, etc.) nous
                  empêchant de fournir la prestation, les sommes versées par le Client
                  seront remboursées intégralement, sans possibilité de
                  réclamation d’indemnités supplémentaires.
                </li>
              </ul>

              <h2>7. Responsabilité et assurances</h2>
              <ul>
                <li>
                  All diffusion s’engage à fournir un matériel conforme et en bon état
                  de fonctionnement. Toutefois, sa responsabilité ne saurait être
                  engagée pour les dommages résultant d’une mauvaise utilisation du
                  matériel par le Client ou ses convives.
                </li>
                <li>
                  Le Client est invité à souscrire une assurance événement s’il estime
                  nécessaire de se prémunir contre d’éventuels incidents ou
                  détériorations (matériel, local, etc.).
                </li>
              </ul>

              <h2>8. Propriété intellectuelle</h2>
              <p>
                Le Client est responsable du respect de la législation relative aux droits
                d’auteur (notamment UNISONO en Belgique) pour la diffusion de toute
                œuvre musicale ou audiovisuelle lors de l’événement. All diffusion ne
                saurait être tenue pour responsable en cas de manquement du Client à ces
                obligations légales.
              </p>

              <h2>9. Modification des CGV</h2>
              <p>
                Nous nous réservons le droit de modifier à tout moment les présentes CGV.
                Toute version actualisée sera publiée sur notre site internet et,
                le cas échéant, communiquée par courrier électronique.
              </p>

              <h2>10. Droit applicable et juridictions compétentes</h2>
              <p>
                Les présentes CGV sont régies par le droit belge, en particulier les
                dispositions en vigueur en Wallonie. En cas de litige, les tribunaux du
                ressort du siège social d’All diffusion (Rue du Château 13, 5190
                Jemeppe-sur-Sambre) sont seuls compétents, sauf dispositions légales
                impératives contraires.
              </p>

              <h2>11. Contact</h2>
              <p>
                Pour toute question relative à nos prestations, à un devis, à la
                modification ou à l’annulation d’une réservation, vous pouvez nous
                contacter :
              </p>
              <ul>
                <li>
                  Par email :{' '}
                  <a href="mailto:contact@niceevents.be" className="text-light">
                    contact@niceevents.be
                  </a>
                </li>
                <li>
                  Par courrier : All diffusion, Rue du Château 13, 5190 Jemeppe-sur-Sambre,
                  Belgique
                </li>
              </ul>

              <div className="text-center mt-4">
                <Link to="/contact" className="btn btn-primary">
                  Contactez-nous
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsAndConditions;
