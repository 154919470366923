import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="container text-center py-5">
      <h1 className="display-1">404</h1>
      <h2>Oups, page introuvable !</h2>
      <p className="lead">
        On dirait que cette page s'est perdue en chemin.<br />
        Peut-être a-t-elle pris un mauvais virage ou s'est-elle fait happer par un trou noir ?<br />
        Pas de panique, l'aventure continue !
      </p>
      <Link to="/" className="btn btn-primary">
        Retourner à l'accueil
      </Link>
    </div>
  );
}

export default NotFound;
