import React from 'react';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {
  return (
    <>
    <Helmet>
    <title>Politique de Confidentialité | Nice Events Sonorisation</title>
    <meta
        name="description"
        content="Consultez la politique de confidentialité de Nice Events Sonorisation et découvrez comment nous protégeons vos données personnelles."
    />
    <link rel="canonical" href="https://niceevents.be/privacy-policy" />

    {/* Open Graph - Réseaux Sociaux */}
    <meta property="og:title" content="Politique de Confidentialité | Nice Events Sonorisation" />
    <meta property="og:description" content="Découvrez la politique de confidentialité complète de Nice Events Sonorisation concernant le traitement et la protection de vos données personnelles." />
    <meta property="og:url" content="https://niceevents.be/privacy-policy" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://niceevents.be/images/og-image.jpg" />

    {/* Twitter Cards (optionnel, actuellement en commentaire) */}
    {/*
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Politique de Confidentialité | Nice Events Sonorisation" />
    <meta name="twitter:description" content="Découvrez la politique de confidentialité complète de Nice Events Sonorisation concernant le traitement et la protection de vos données personnelles." />
    <meta name="twitter:image" content="https://niceevents.be/images/og-image.jpg" />
    */}

    {/* JSON-LD Structuré SEO optimisé pour la politique de confidentialité */}
    <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        url: "https://niceevents.be/privacy-policy",
        name: "Politique de Confidentialité | Nice Events Sonorisation",
        description: "Politique de confidentialité détaillée expliquant la gestion et la protection des données personnelles par Nice Events Sonorisation."
        })}
    </script>
    </Helmet>



      {/* Section principale avec fond image responsive */}
      <section
        className="privacy-page position-relative text-light"
        style={{
          backgroundColor: '#333',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '600px'
        }}
        role="main"
      >
        {/* Overlay sombre */}
        <div
          className="overlay position-absolute w-100 h-100"
          style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
          aria-hidden="true"
        ></div>

        <div className="privacy-content container position-relative" style={{ zIndex: 1, paddingTop: '100px', paddingBottom: '100px' }}>
          <div className="content-frame">
            <h1 className="display-4 text-center">Politique de Confidentialité</h1>
            <p className="lead text-center">Votre vie privée est importante pour nous.</p>
            
            <div className="rich-text mt-5">
              <h2>Collecte des données</h2>
              <p>
                Nous n'utilisons que des cookies non commerciaux pour enregistrer vos préférences de navigation. La seule donnée collectée est l'adresse email que vous fournissez via notre formulaire de contact. Ces informations sont utilisées uniquement pour répondre à vos demandes et vous fournir nos services.
              </p>
              
              <h2>Partage des données</h2>
              <p>
                Nous respectons votre vie privée et ne partageons jamais vos données personnelles, y compris votre adresse email, avec des tiers.
              </p>
              
              <h2>Sécurité des données</h2>
              <p>
                Nous mettons en œuvre des mesures de sécurité pour protéger vos informations contre tout accès non autorisé, modification ou divulgation.
              </p>
              
              <h2>Mises à jour de la politique</h2>
              <p>
                Nous pouvons mettre à jour cette politique de confidentialité. Toute modification sera affichée sur cette page et, le cas échéant, communiquée par email.
              </p>
              
              <h2>Vos droits</h2>
              <p>
                Conformément à la législation belge et au RGPD, vous disposez notamment des droits suivants :
              </p>
              <ul>
                <li>Droit d'accès à vos données personnelles</li>
                <li>Droit de rectification</li>
                <li>Droit à l'effacement</li>
                <li>Droit à la limitation du traitement</li>
                <li>Droit d'opposition</li>
                <li>Droit à la portabilité des données</li>
              </ul>
              <p>
                Pour exercer ces droits, contactez-nous par email à <a href="mailto:contact@niceevents.be" className="text-light">contact@niceevents.be</a> ou par courrier à Rue du Château 13, 5190 Jemeppe-sur-Sambre, Belgique.
              </p>
              
              <h2>Contact</h2>
              <p>
                Pour toute question concernant notre politique de confidentialité, veuillez contacter <strong>Sébastien Gramtinne</strong> à l'adresse <a href="mailto:s.gramtinne@gmail.com" className="text-light">s.gramtinne@gmail.com</a>.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
