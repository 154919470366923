import React from 'react';
import { Helmet } from 'react-helmet';

function LegalMentions() {
  return (
    <>
    <Helmet>
    <title>Mentions Légales | Nice Events Sonorisation</title>
    <meta
        name="description"
        content="Consultez les mentions légales du site Nice Events Sonorisation, spécialiste en animation DJ, quiz interactifs, karaoké et événements en Belgique."
    />
    <link rel="canonical" href="https://niceevents.be/legal-mentions" />

    {/* Open Graph - Réseaux Sociaux */}
    <meta property="og:title" content="Mentions Légales | Nice Events Sonorisation" />
    <meta property="og:description" content="Accédez aux mentions légales complètes de Nice Events Sonorisation, expert en animations événementielles en Belgique." />
    <meta property="og:url" content="https://niceevents.be/legal-mentions" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://niceevents.be/images/og-image.jpg" />

    {/* Twitter Cards (optionnel, actuellement en commentaire) */}
    {/*
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Mentions Légales | Nice Events Sonorisation" />
    <meta name="twitter:description" content="Accédez aux mentions légales complètes de Nice Events Sonorisation, expert en animations événementielles en Belgique." />
    <meta name="twitter:image" content="https://niceevents.be/images/image.jpg" />
    */}

    {/* JSON-LD Structuré SEO optimisé pour la page Mentions légales */}
    <script type="application/ld+json">
        {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        url: "https://niceevents.be/legal-mentions",
        name: "Mentions Légales | Nice Events Sonorisation",
        description: "Mentions légales complètes du site Nice Events Sonorisation, spécialiste de l'événementiel en Belgique."
        })}
    </script>
    </Helmet>


      <section
        className="legal-mentions-page position-relative text-light"
        role="main"
        style={{
          backgroundColor: '#333',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '600px'
        }}
      >
        {/* Overlay sombre pour homogénéiser le fond */}
        <div
          className="overlay position-absolute w-100 h-100"
          style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
          aria-hidden="true"
        ></div>

        <div className="legal-content container position-relative" style={{ zIndex: 1, paddingTop: '100px', paddingBottom: '100px' }}>
        <div className="content-frame">
            <h1 className="display-4 text-center">Mentions Légales</h1>
            <p className="lead text-center">Informations légales concernant Nice Events Sonorisation</p>

            <div className="rich-text mt-5">
            <h2>Éditeur</h2>
            <p>
                <strong>All Diffusion SNC</strong><br />
                Rue du Château 13, 5190 Jemeppe-sur-Sambre, Belgique<br />
                <strong>BCE :</strong> 1016.199.219<br />
                <strong>TVA :</strong> BE 1016.199.219<br />
                Email : <a href="mailto:contact@niceevents.be" className="text-light">contact@niceevents.be</a>
            </p>

            <h2>Hébergeur</h2>
            <p>
                OVH Groupe SA<br />
                2, rue Kellermann, 59100 Roubaix (France)
            </p>

            <h2>Propriété intellectuelle</h2>
            <p>
                Tous les contenus présents sur ce site, y compris textes, images, graphismes, logos et icônes, sont la propriété exclusive de Nice Events Sonorisation ou de ses partenaires. Toute reproduction ou utilisation non autorisée est strictement interdite.
            </p>

            <h2>Données personnelles</h2>
            <p>
                Pour plus d'informations sur la collecte et l'utilisation de vos données personnelles, veuillez consulter notre <a href="/privacy-policy" className="text-light">Politique de Confidentialité</a>.
            </p>

            <h2>Contact</h2>
            <p>
                Pour toute question relative aux mentions légales, veuillez contacter <strong>Sébastien Gramtinne</strong> à l'adresse <a href="mailto:s.gramtinne@gmail.com" className="text-light">contact@niceevents.be</a>.
            </p>
            </div>
        </div>
        </div>

      </section>
    </>
  );
}

export default LegalMentions;
