import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import OurKits from '../assets/images/our-kits.webp';

// -- Données de vos kits
const kitsData = [
  {
    title: 'Kit Essentiel',
    people: '100 personnes',
    items: [
      'Deux haut-parleurs QSC K10',
      'Un subwoofer Pioneer XPRS 115S',
      'Une perche à lumière 4 spots RGB + 4 effets',
      'Une console mixage Pioneer XDJ-RR',
      'Un micro filaire SHURE SM58',
      'Prestation montage + DJ sans supplément',
    ],
    price: '500€ htva',
  },
  {
    title: 'Kit Ambiance',
    people: '150 personnes',
    items: [
      'Deux haut-parleurs QSC K10',
      'Deux subwoofers Pioneer XPRS 115S',
      'Une perche 4 spots RGB + 4 effets',
      'Une console mixage Pioneer XDJ-RR',
      'Un micro filaire SHURE SM58',
      'Un micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: '550€ htva',
  },
  {
    title: 'Kit Dynamique',
    people: '150 personnes',
    items: [
      'Deux haut-parleurs QSC K10',
      'Deux subwoofers Pioneer XPRS 115S',
      'Une perche 4 spots RGB + 4 effets + 1 laser RGB',
      'Deux spots de sol RGB',
      'Une machine à brouillard JBSYSTEMS HAZE-700',
      'Une console mixage Pioneer XDJ-RR',
      'Un micro filaire SHURE SM58',
      'Un micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: '600€ htva',
  },
  {
    title: 'Kit Impact',
    people: '250 personnes',
    items: [
      'Deux haut-parleurs QSC K12',
      'Un subwoofer QSC KW181',
      'Deux perches 8 spots RGB + 8 effets + 2 lasers RGB',
      'Quatre spots de sol RGB',
      'Une machine à brouillard JBSYSTEMS HAZE-700',
      'Une table de mixage Pioneer DKM-900NXS',
      'Deux lecteurs Pioneer CDJ-2000NXS',
      'Un micro filaire SHURE SM58',
      'Un micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: '700€ htva',
  },
  {
    title: 'Kit Prestige',
    people: '300 personnes',
    items: [
      'Deux haut-parleurs QSC K12',
      'Deux caissons de basse QSC KW181',
      'Structure 4 mètres + 4 scans + 2 effets beam + 1 laser RGB',
      'Six spots de sol RGB',
      'Machine à brouillard JBSYSTEMS HAZE-700',
      'Table de mixage Pioneer DJM-900NXS',
      'Deux lecteurs Pioneer CDJ-2000NXS',
      'Micro filaire SHURE SM58',
      'Micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: '800€ htva',
  },
  {
    title: 'Kit Ultime',
    people: '500 personnes ou plus',
    items: [
      'Quatre haut-parleurs QSC K12',
      'Deux caissons de basse QSC KW181',
      'Structure 5 mètres avec 6 scanneurs, 4 effets beam + 2 lasers RGB',
      'Huit spots de sol RGB',
      'Machine à brouillard JBSYSTEMS HAZE-700',
      'Table de mixage Pioneer DJM-900NXS',
      'Deux lecteurs Pioneer CDJ-2000NXS',
      'Un micro filaire SHURE SM58',
      'Un micro sans fil',
      'Prestation montage + DJ sans supplément',
    ],
    price: '1000€ htva',
  },
  {
    title: 'Kit Quiz interactif',
    people: 'Entre 10 et 500 personnes',
    items: [
      'Kit quiz avec télécommande',
      'Un écran géant + projecteur',
      'Écran LCD sur pied (supplément de 35€)',
      'Une console mixage Pioneer XDJ-RR',
      'Quiz selon vos goûts',
      'Quatre spots de sol RGB',
      'Kit son et lumière sur devis',
      'Prestation montage + DJ sans supplément',
    ],
    price: '200€ htva (sans matériel son et lumières)',
  },
  {
    title: 'Kit Karaoké',
    people: 'Entre 10 et 500 personnes',
    items: [
      'Deux micros filaires sur pied SHURE SM58',
      'Un retour chanteur Pioneer XPRS 15',
      'Un écran géant + projecteur',
      'Écran LCD sur pied (supplément de 35€)',
      'Une console mixage Pioneer XDJ-RR',
      'Plus de 70.000 chansons',
      'Quatre spots de sol RGB',
      'Kit son et lumière sur devis',
      'Prestation montage + DJ sans supplément',
    ],
    price: '200€ htva (sans matériel son et lumières)',
  },
];

function Kits() {
  return (
    <>
      <Helmet>
        {/* Title optimisé */}
        <title>
          Nos Kits Événementiels | Sonorisation & Éclairage - Nice Events
          Sonorisation
        </title>
        {/* Meta Description optimisée (~155 caractères) */}
        <meta
          name="description"
          content="Nice Events Sonorisation propose des kits complets prêts à l’emploi pour vos événements en Belgique et France : DJ animateur, sonorisation et éclairage."
        />
        <link rel="canonical" href="https://niceevents.be/kits" />

        {/* Open Graph - Réseaux Sociaux */}
        <meta
          property="og:title"
          content="Nos Kits Événementiels | Sonorisation & Éclairage - Nice Events Sonorisation"
        />
        <meta
          property="og:description"
          content="Kits tout-en-un pour des événements réussis : sonorisation, éclairage, DJ animateur et accessoires professionnels."
        />
        <meta property="og:url" content="https://niceevents.be/kits" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://niceevents.be/images/og-image.jpg"
        />

        {/* (Optionnel) Twitter Cards
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nos Kits Événementiels | Sonorisation & Éclairage - Nice Events Sonorisation" />
        <meta name="twitter:description" content="Kits tout-en-un pour des événements réussis : sonorisation, éclairage, DJ animateur et accessoires professionnels." />
        <meta name="twitter:image" content="https://niceevents.be/images/og-image.jpg" />
        */}

        {/* Balise JSON-LD pour fiches de marchand / produits */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: 'Kits Événementiels Nice Events Sonorisation',
            image: 'https://niceevents.be/images/og-image.jpg',
            description:
              'Location de kits événementiels tout compris : DJ animateur, sonorisation professionnelle, éclairages, matériel DJ et accessoires événementiels.',
            brand: {
              '@type': 'Brand',
              name: 'Nice Events Sonorisation',
              logo: 'https://niceevents.be/images/logo.jpg',
            },
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '5',
              reviewCount: '5',
            },
            review: [
              {
                '@type': 'Review',
                author: {
                  '@type': 'Person',
                  name: 'Anne-Laure',
                },
                datePublished: '2024-12-13',
                reviewBody:
                  'Sébastien a mis une ambiance de feu à notre mariage ! Nous avions donné une ligne directrice pour la musique et il s’est adapté sans problème aux demandes de nos invités ! Nous le recommandons vivement !',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
              {
                '@type': 'Review',
                author: {
                  '@type': 'Person',
                  name: 'Kathleen',
                },
                datePublished: '2024-11-23',
                reviewBody:
                  'Super soirée menée par Seb en DJ. Public de jeunes 17-19 ans. Ils étaient ravis! Nous avions préparé une playlist de base que Seb a agrémentée avec succès après des jeunes :-) À recommander sans hésitation pour passer un super moment de fête!',
                reviewRating: {
                  '@type': 'Rating',
                  ratingValue: '5',
                },
              },
            ],
            // Pour chaque kit, on crée une offre distincte :
            offers: kitsData.map((kit) => {
              // Extraction numérique du prix (avant le symbole "€")
              const priceValue = parseFloat(
                kit.price.replace(/\s*€.*$/i, '').trim()
              );

              // Génération d'une ancre unique pour l'URL
              const anchor = kit.title
                .toLowerCase()
                .replace(/[éèêë]/g, 'e')
                .replace(/[àâ]/g, 'a')
                .replace(/\s+/g, '-')
                .replace(/[^a-z0-9-]/g, '');

              return {
                '@type': 'Offer',
                availability: 'https://schema.org/InStock',
                priceCurrency: 'EUR',
                price: priceValue,
                priceValidUntil: '2025-12-31',
                url: `https://niceevents.be/kits#${anchor}`,
                // Zone de service (Belgique & France)
                areaServed: ['BE', 'FR'],
                // Politique de retour
                hasMerchantReturnPolicy: {
                  '@type': 'MerchantReturnPolicy',
                  returnPolicyCategory:
                    'https://schema.org/MerchantReturnFiniteReturnWindow',
                  applicableCountry: ['BE', 'FR'],
                  merchantReturnDays: 14,
                  returnMethod: 'https://schema.org/ReturnByMail',
                  returnFees: 'https://schema.org/FreeReturn',
                },
                // Informations de livraison
                shippingDetails: {
                  '@type': 'OfferShippingDetails',
                  shippingRate: {
                    '@type': 'MonetaryAmount',
                    value: 0,
                    currency: 'EUR',
                  },
                  shippingDestination: {
                    '@type': 'DefinedRegion',
                    addressCountry: ['BE', 'FR'],
                  },
                  deliveryTime: {
                    '@type': 'ShippingDeliveryTime',
                    handlingTime: {
                      '@type': 'QuantitativeValue',
                      minValue: 0,
                      maxValue: 1,
                      unitCode: 'DAY',
                    },
                    transitTime: {
                      '@type': 'QuantitativeValue',
                      minValue: 1,
                      maxValue: 2,
                      unitCode: 'DAY',
                    },
                  },
                },
              };
            }),
          })}
        </script>
      </Helmet>

      {/* Bannière avec image responsive */}
      <div
        className="kits-banner position-relative text-center text-light d-flex align-items-center justify-content-center mb-5"
        style={{ minHeight: '300px' }}
      >
        <img
          src={OurKits}
          alt="Hero Image - Image de présentation principale"
          className="img-fluid w-100"
          style={{ height: '400px', objectFit: 'cover' }}
        />
        <div className="overlay-animated" />
        <div
          className="position-absolute top-50 start-50 translate-middle hero-text-effect"
          style={{ zIndex: 2 }}
        >
          <h1 className="display-3 fw-bold">Nos Kits</h1>
          <p className="lead">
            Explorez nos différentes options adaptées à votre événement
            <br />
            Vous désirez personnalisez votre projet&nbsp;? <br />
            <Link style={{ textDecoration: 'underline' }} to="/contact">
              Demandez votre devis gratuit
            </Link>
          </p>
        </div>
      </div>

      {/* Contenu principal */}
      <div className="container py-5 fade-in">
        <p className="lead text-center mb-5">
          Sublimez vos événements avec nos kits de sonorisation et d’éclairage
          professionnels, conçus pour répondre à toutes vos exigences.
        </p>

        <div className="row">
          {kitsData.map((kit, index) => {
            // URL d'exemple (remplacez par vos propres images si souhaité)
            const imageUrl = `https://dummyimage.com/600x300/ccc/666.png&text=${encodeURIComponent(
              kit.title
            )}`;

            return (
              <div key={index} className="col-md-6 mb-4 d-flex">
                <div className="card h-100 flex-fill" id={kit.title
                  .toLowerCase()
                  .replace(/[éèêë]/g, 'e')
                  .replace(/[àâ]/g, 'a')
                  .replace(/\s+/g, '-')
                  .replace(/[^a-z0-9-]/g, '')}
                >
                  <img
                    src={imageUrl}
                    alt={`Kit ${kit.title}`}
                    className="card-img-top img-fluid"
                  />
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">{kit.title}</h5>
                    <h6 className="card-subtitle mb-2">{kit.people}</h6>
                    <ul className="list-group list-group-flush my-3 flex-grow-1">
                      {kit.items.map((item, i) => (
                        <li key={i} className="list-group-item">
                          <i className="fas fa-check me-2"></i> {item}
                        </li>
                      ))}
                    </ul>
                    {/* Prix en bas de la carte */}
                    <div className="mt-auto">
                      <p className="card-text fw-bold">Prix : {kit.price}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="container my-5">
        <div className="text-center d-flex flex-column align-items-center gap-3">
          <Link to="/contact" className="btn btn-primary btn-lg">
            Contactez-nous
          </Link>
          <Link to="/quote-form" className="btn btn-primary btn-lg">
            Demander un devis rapide
          </Link>
          <p className="mt-4">
            Découvrez aussi nos{' '}
            <Link to="/services" style={{ textDecoration: 'underline' }}>
              Services
            </Link>{' '}
            ou retournez à la{' '}
            <Link to="/" style={{ textDecoration: 'underline' }}>
              page d’accueil
            </Link>
            .
          </p>
        </div>
      </div>



      {/* SVG décoratif en bas de page */}
      <svg
        className="section-decorator"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#d32f2f"
          fillOpacity="1"
          d="M0,256L60,224C120,192,240,128,360,112C480,96,600,128,720,138.7C840,149,960,139,1080,149.3C1200,160,1320,192,1380,208L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </>
  );
}

export default Kits;
