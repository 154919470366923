import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  // À chaque changement de route, ferme le menu collapse s'il est ouvert
  React.useEffect(() => {
    const navCollapse = document.getElementById("navbarNavAltMarkup");
    if (navCollapse && navCollapse.classList.contains("show")) {
      const bsCollapse = window.bootstrap && window.bootstrap.Collapse.getInstance(navCollapse);
      if (bsCollapse) {
        bsCollapse.hide();
      }
    }
  }, [location]);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-3">
      <Link className="navbar-brand" to="/">
        <img
          src={require('../assets/navbar-logo.png')}
          alt="Nice Events Logo (Navbar logo)"
          style={{ height: '35px' }}
        />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav ms-auto">
          <Link className="nav-link" to="/">Accueil</Link>
          <Link className="nav-link" to="/services">Services</Link>
          <Link className="nav-link" to="/kits">Nos Kits</Link>
          <Link className="nav-link" to="/quote-form">Devis rapide</Link>
          <Link className="nav-link" to="/contact">Contact</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
