import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import HeroImage from '../assets/images/hero-image.webp';
import DjService from '../assets/images/dj-service.webp';
import QuizInteractif from '../assets/images/quiz-interactif.webp';
import Karaoke from '../assets/images/karaoke.webp';

function Home() {
  return (
    <>
      <Helmet>
        {/* Title optimisé : environ 60-65 caractères */}
        <title>Nice Events Sonorisation | DJ, Quiz & Karaoké en Belgique</title>
        {/* Meta Description optimisée ~155 caractères */}
        <meta
          name="description"
          content="Nice Events Sonorisation : DJ, quiz interactifs, karaoké & matériel haut de gamme pour des événements inoubliables partout en Belgique."
        />
        <link rel="canonical" href="https://niceevents.be/" />

        {/* Open Graph - Réseaux Sociaux */}
        <meta
          property="og:title"
          content="Nice Events Sonorisation | DJ, Quiz & Karaoké en Belgique"
        />
        <meta
          property="og:description"
          content="Créez des moments d’exception avec Nice Events : DJ professionnel, quiz interactifs, karaoké et équipement de sonorisation en Belgique."
        />
        <meta property="og:url" content="https://niceevents.be/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://niceevents.be/images/og-image.jpg" />

        {/* Twitter Cards (optionnel, laissé en commentaire)
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Nice Events Sonorisation | DJ, Quiz & Karaoké en Belgique"
        />
        <meta
          name="twitter:description"
          content="Créez des moments d’exception avec Nice Events : DJ professionnel, quiz interactifs, karaoké et équipement de sonorisation en Belgique."
        />
        <meta name="twitter:image" content="https://niceevents.be/images/og-image.jpg" />
        */}

        {/* JSON-LD Structuré SEO optimisé */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: 'https://niceevents.be',
            name: 'Nice Events Sonorisation',
            description:
              'DJ, quiz interactifs, karaoké et matériel événementiel de qualité en Belgique.'
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Nice Events Sonorisation',
            url: 'https://niceevents.be',
            logo: 'https://niceevents.be/images/logo.jpg',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '+32475770204',
              contactType: 'Service client',
              areaServed: 'BE',
              availableLanguage: 'French'
            }
          })}
        </script>
      </Helmet>

      {/* Section Hero */}
      <div className="hero-section text-center text-light">
        <img
          src={HeroImage}
          alt="Nice Events Sonorisation - Nos services DJ, son et lumière"
          className="hero-img"
        />
        <div className="overlay-animated" />
        <div className="hero-content container d-flex flex-column align-items-center justify-content-center">
          <h1 className="display-3 fw-bold mb-3 hero-text-effect">
            Nice Events
          </h1>
          <p className="lead mb-0">
            Nous assurons l’animation de tous vos événements : mariages, anniversaires,
            soirées d’entreprise, quiz interactifs et karaoké, avec un matériel 
            professionnel son &amp; lumière partout en Belgique.
          </p>
        </div>
      </div>

      {/* Section Points Forts */}
      <div className="container py-5 fade-in">
        <p className="lead text-center mb-5">
          Vivez une expérience événementielle inoubliable avec nos services de DJ,
          quiz interactif, karaoké et matériel haut de gamme.
        </p>
        <div className="row text-center mb-1">
          <div className="col-md-4 mb-3">
            <i className="fas fa-music fa-3x mb-3" aria-hidden="true"></i>
            <h4>Animations DJ</h4>
            <p>Une ambiance musicale sur mesure pour sublimer chaque instant.</p>
          </div>
          <div className="col-md-4 mb-3">
            <i className="fas fa-question-circle fa-3x mb-3" aria-hidden="true"></i>
            <h4>Quiz Interactifs</h4>
            <p>Stimulez l’engagement de vos invités avec des quiz ludiques et interactifs.</p>
          </div>
          <div className="col-md-4 mb-3">
            <i className="fas fa-microphone-alt fa-3x mb-3" aria-hidden="true"></i>
            <h4>Karaoké</h4>
            <p>Lâchez-vous et révélez vos talents lors de soirées mémorables.</p>
          </div>
        </div>
      </div>

      {/* Section Services & Atouts */}
      <div className="container mb-5 fade-in">
        <h2 className="text-center mb-4">
          Votre Expert en Son &amp; Lumière, DJ, Quiz et Karaoké
        </h2>
        <p>
          Chez <strong>Nice Events Sonorisation</strong>, nous avons à cœur de faire
          de votre événement un moment d’exception. Spécialisés en <em>DJing</em>, 
          sonorisation et éclairage, nous créons une ambiance unique adaptée à
          votre style et à vos envies. Mariages, <strong>team-building</strong>,
          <strong> anniversaires</strong> ou toute autre célébration, nous disposons
          du <strong>matériel professionnel</strong> nécessaire pour vous offrir
          une expérience clé en main&nbsp;:
        </p>
        <ul>
          <li>Systèmes audio haute performance pour une qualité sonore optimale</li>
          <li>Éclairages dynamiques et effets lumineux sur mesure</li>
          <li>Large catalogue musical avec playlists personnalisées</li>
          <li>Matériel de quiz interactifs pour dynamiser vos soirées</li>
          <li>Équipement karaoké de dernière génération</li>
        </ul>
        <p>
          Basés en Belgique et également actifs en France, nous nous déplaçons
          partout afin d’offrir à vos convives une <strong>ambiance mémorable</strong>.
          Contactez-nous pour un devis personnalisé et concrétisons ensemble
          l’événement dont vous rêvez&nbsp;!
        </p>
      </div>

      {/* Contenu principal avec images réelles */}
      <div className="container fade-in">
        <div className="row">
          <div className="col-md-4 mb-4">
            <img
              src={DjService}
              alt="DJ Service - Illustration de nos prestations DJ"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-4 mb-4">
            <img
              src={QuizInteractif}
              alt="Quiz Interactif - Illustration de nos quiz ludiques"
              className="img-fluid rounded shadow-lg"
            />
          </div>
          <div className="col-md-4 mb-4">
            <img
              src={Karaoke}
              alt="Karaoké - Illustration de nos soirées karaoké"
              className="img-fluid rounded shadow-lg"
            />
          </div>
        </div>

        <div className="text-center mt-5">
          <Link to="/services" className="btn btn-primary btn-lg">
            Découvrir nos services
          </Link>
        </div>
      </div>

      {/* Décorateur de section */}
      <svg
        className="section-decorator"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#d32f2f"
          fillOpacity="1"
          d="M0,256L60,224C120,192,240,128,360,112C480,96,600,128,720,138.7C840,149,960,139,1080,149.3C1200,160,1320,192,1380,208L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </>
  );
}

export default Home;
