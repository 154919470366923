import React, { useState, useEffect } from 'react';

function CookieBanner() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Vérifie si l'utilisateur a déjà accepté les cookies
    const accepted = localStorage.getItem('cookiesAccepted');
    if (!accepted) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="cookie-banner bg-secondary text-light py-3 fixed-bottom" style={{ zIndex: 1050 }}>
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
        <p className="mb-2 mb-md-0">
          Ce site utilise des cookies pour améliorer votre expérience. En poursuivant votre navigation, vous acceptez leur utilisation. Consultez notre{' '}
          <a href="/privacy-policy" className="text-light text-decoration-underline">
            Politique de Confidentialité
          </a>.
        </p>
        <button className="btn btn-primary" onClick={handleAccept}>
          Accepter
        </button>
      </div>
    </div>
  );
}

export default CookieBanner;
