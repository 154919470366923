import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer
      className="footer mt-auto text-light py-4"
      style={{
        background: 'linear-gradient(90deg, #343a40, #23272b)'
      }}
    >
      <div className="container text-center">
        {/* Informations de contact */}
        <p className="mb-1">
          <strong>Nice Events Sonorisation</strong><br />
          Rue du Château 13, 5190 Jemeppe-sur-Sambre, Belgique<br />
          +32 475 770 204<br />
          <a href="mailto:contact@niceevents.be">contact@niceevents.be</a>
        </p>
        <hr />
        {/* Menu de navigation */}
        <nav className="mb-3">
          <ul className="list-inline">
            <li className="list-inline-item mx-2">
              <Link to="/" className="text-light text-decoration-none hover-underline">Accueil</Link>
            </li>
            <li className="list-inline-item mx-2">
              <Link to="/services" className="text-light text-decoration-none hover-underline">Services</Link>
            </li>
            <li className="list-inline-item mx-2">
              <Link to="/kits" className="text-light text-decoration-none hover-underline">Kits</Link>
            </li>
            <li className="list-inline-item mx-2">
              <Link to="/quote-form" className="text-light text-decoration-none hover-underline">Devis rapide</Link>
            </li>
            <li className="list-inline-item mx-2">
              <Link to="/contact" className="text-light text-decoration-none hover-underline">Contact</Link>
            </li>
          </ul>
        </nav>

        {/* Liens légaux */}
        <div className="mb-3 d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <Link to="/privacy-policy" className="text-light me-sm-3 mb-2 mb-sm-0 hover-underline">
            Politique de Confidentialité
        </Link>
        <Link to="/legal-mentions" className="text-light me-sm-3 mb-2 mb-sm-0 hover-underline">
            Mentions Légales
        </Link>
        <Link to="/terms-and-conditions" className="text-light hover-underline">
            Conditions Générales de Vente
        </Link>
        </div>

        <hr />
        {/* Réseaux sociaux */}
        
        <div className="mb-3">
          {/*
          <a
            href="https://www.facebook.com/niceevents"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light me-3"
            aria-label="Facebook"
          >
            <i className="fab fa-facebook fa-lg"></i>
          </a>
          <a
            href="https://www.instagram.com/niceevents"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light me-3"
            aria-label="Instagram"
          >
            <i className="fab fa-instagram fa-lg"></i>
          </a>
          */}
          <a
            href="https://g.co/kgs/7NFVHSH"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light me-3"
            aria-label="Google Établissement"
          >
          <i className="fab fa-google fa-lg"></i>
          </a>
          <a
            href="https://wa.me/32475770204"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light me-3"
            aria-label="WhatsApp"
          >
            <i className="fab fa-whatsapp fa-lg"></i>
          </a>
        </div>
        {/* Copyright */}
        <p className="small mt-2">
            &copy; {new Date().getFullYear()}Made With <i className="fas fa-heart text-danger"></i> Love By{' '}
          <a
            href="https://cyberglow.be"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light hover-underline"
          >
            Cyber Glow
          </a>
        </p>

        {/* Bouton Retour en haut discret */}
        <div className="mt-3">
          <a
            href="#top"
            className="back-to-top text-light"
            style={{
              fontSize: '0.85rem',
              opacity: 0.7,
              transition: 'opacity 0.3s ease'
            }}
            onMouseOver={(e) => (e.currentTarget.style.opacity = 1)}
            onMouseOut={(e) => (e.currentTarget.style.opacity = 0.7)}
          >
            <i className="fas fa-arrow-up"></i> Retour en haut
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
